const GenericImagesData = [
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-2.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-raspberry-yogurt.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-3.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-ice-cream-stick.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-4.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-waffle-bowl.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-5.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-6.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-yogurt-bowl-waffle-bowl.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-7.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-8.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-9.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-10.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-11.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-vanilla-bowl.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-12.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-13.webp',
  },
  {
    image:
      'https://koalaberry.s3.us-east-2.amazonaws.com/koala-berry-image-14.webp',
  },
];

export default GenericImagesData;
